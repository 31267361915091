import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="T" transform="translate(11.000000, 5.000000)">
        <path
          d="M54.39,37.8c-.02.28-.05.47-.11.56-.05.1-.21.18-.47.22-.57.13-.94-.09-1.13-.64-.66-2.04-1.44-3.39-2.35-4.07-1.26-.93-3.45-1.4-6.6-1.4-1.27,0-1.97.14-2.09.41-.04.09-.07.22-.07.39v22.93c0,.98.23,1.67.69,2.06.46.39,1.29.88,2.66,1.21.58.12,1.17.24,1.25.29.56,0,.61,1.28-.45,1.28-.03.02-12.52,0-12.52,0,0,0-.87-.53-.09-1.23.33-.15.93-.41.93-.41,0,0,2.21-.74,2.67-1.22.52-.54.78-1.4.78-2.6v-22.29c0-.45-.28-.71-.83-.78-.06-.02-.51-.02-1.35-.02-2.91,0-5.06.5-6.44,1.52-1.04.75-1.97,2.13-2.78,4.14-.26.63-.62.83-1.08.62-.52-.12-.71-.41-.58-.85.54-1.66.81-4,.81-7.02,0-.35.47-.71,1.4-.64l25.79.07c.86-.13,1.32.01,1.4.41.43,2.04.62,4.4.58,7.08Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
